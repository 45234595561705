import React from 'react';
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../components/navbar';

const UserCreatePasswordPage = props => {
    return(
        <div>
            <TopNavBar />
            <hr />
            <div className="container h-100">
                <div className="row justify-content-center h-100">
                    <div className="col-md-6 col-sm-6">
                        <form action="#" method="post" className="sky-form boxed">
                            <header className="fs-18 mb-20">
                                Please Create A Password to Continue Registration
                            </header>
                            <fieldset className="m-0">
                                <label className="input mb-10">
                                    <i className="ico-append fa fa-envelope"> </i>
                                    <input required="" type="password" placeholder="Password" />
                                    <b className="tooltip tooltip-bottom-right">Only latin characters and numbers</b>
                                </label>
                                <label className="input mb-10">
                                    <i className="ico-append fa fa-lock"> </i>
                                    <input required="" type="password" placeholder="Verify Password" />
                                    <b className="tooltip tooltip-bottom-right">Passwords must match</b>
                                </label>
                            </fieldset>
                            <footer>
                                <button type="submit" className="btn btn-primary rad-0 float-right">
                                    <i className="fa fa-check"> </i> OK, SET PASSWORD
                                </button>
                            </footer>
                        </form>
                        {/*<div className="alert alert-mini alert-danger mb-30">*/}
                        {/*    <strong>Oh snap!</strong> Login Incorrect!*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ store: { app }}) => ({

}))(observer(UserCreatePasswordPage));
