import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useCookies } from 'react-cookie';
//import { navigate } from '@reach/router';
import SimpleReactValidator from 'simple-react-validator';
import TopNavBar from '../../components/navbar';

// function useForceUpdate() {
//     const [value, set] = useState(true);
//     return () => set(!value);
// }

const UserFirstVisitPage = props => {
    let buttonRef = React.createRef();
    let validator = new SimpleReactValidator({autoForceUpdate: this});

    const {
        visitorInfo,
        // eslint-disable-next-line no-unused-vars
        updateVisitorFirstName,
        // eslint-disable-next-line no-unused-vars
        updateVisitorLastName,
        updateVisitorEmail,
        updateVisitorPassword
    } = props;

    const errorStyle = {
        color: 'red'
    };

    // function isValidForm() {
    //     //let messages = validator.getErrorMessages();
    //     //return messages.email === null && messages.password === null;
    // }

    function getMessages() {
        return validator.getErrorMessages();
    }

    function getMessageForFirstName() {
        var message = validator.getErrorMessages().firstName;
        return message;
    }

    function getMessageForLastName() {
        var message = validator.getErrorMessages().lastName;
        return message;
    }

    function getMessageForEmail(fieldName) {
        var message = validator.getErrorMessages().email;
        return message;
    }

    function getMessageForPassword(fieldName) {
        var message = validator.getErrorMessages().password;
        return message;
    }

/*    function RenderLoginError(message) {
        console.log('Login Message: ' + message);
        if (message === 'Unauthorized') {
            updateShowVisitorLoginError(true);
            console.log(showVisitorLoginError);
        } else {
            updateShowVisitorLoginError(false);
        }
    }*/

    const [cookies] = useCookies(['loginToken', 'keepLoggedIn']);
    //const { values, handleChange, handleSubmit } = useForm();

    //const forceUpdate = useForceUpdate();

    // const handleSetVisitorFirstName = (e) => {
    //     updateVisitorFirstName(e.target.value);
    // };
    //
    // const handleSetVisitorLastName = (e) => {
    //     updateVisitorLastName(e.target.value);
    // };

    const handleSetVisitorEmail = (e) => {
        updateVisitorEmail(e.target.value);
    };

    const handleSetVisitorPassword = (e) => {
        updateVisitorPassword(e.target.value);
    };


    const enterPressed = (e) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            console.log(buttonRef);
            //buttonRef.onclick();
            //buttonRef.click();
            //console.log('enter key pressed');
        }
    };

    useEffect(() => {
        validator.purgeFields();
        // eslint-disable-next-line no-unused-vars
        let keepLoggedInCookie = cookies.keepLoggedIn;
        // eslint-disable-next-line no-unused-vars
        let loginTokenCookie = cookies.loginToken;

        // TODO: handle keepLoggedInCookie === false case
    });

    return(
        <div>
            <TopNavBar />
            <hr />
            <div className="container h-100 login-form">
                <div className="row justify-content-center h-100">
                    <div className="col-md-6 col-sm-6">
                        <form className="sky-form boxed">
                            <header className="fs-18 mb-20">
                                Let Us Know About Yourself
                            </header>
                            <div className="form-row">
                                <div className="form-group col-md-12">

                                </div>
                            </div>
                            <fieldset className="m-0">
                                <label className="input mb-10">
                                    <input type="text"
                                           className="form-control"
                                           id="txtMobilePhone"
                                           name="mobilePhone"
                                           placeholder="Mobile Phone"
                                           defaultValue={visitorInfo.mobilePhone}
                                           readOnly={true}
                                    />
                                </label>
                                <label className="input mb-10">
                                    <input type="text"
                                           className="form-control"
                                           id="txtFirstName"
                                           name="firstName"
                                           placeholder="First Name"
                                           defaultValue={visitorInfo.firstName}
                                    />
                                </label>
                                {validator.message('firstName', visitorInfo.firstName, 'required')}
                                <label className="input mb-10">
                                    <input type="text"
                                           className="form-control"
                                           id="txtLastName"
                                           name="lastName"
                                           placeholder="Last Name"
                                           defaultValue={visitorInfo.lastName}
                                    />
                                </label>
                                {validator.message('lastName', visitorInfo.lastName, 'required')}
                                <label className="input mb-10">
                                    <i className="ico-append fa fa-envelope"> </i>
                                    <input required=""
                                           type="email"
                                           placeholder="Email address"
                                           defaultValue={visitorInfo.email}
                                           onChange={(e) => handleSetVisitorEmail(e)} />

                                    {/*<b className="tooltip tooltip-bottom-right">Needed to verify your account</b>*/}
                                </label>
                                {validator.message('email', visitorInfo.email, 'required|email')}
                                <label className="input mb-10">
                                    <i className="ico-append fa fa-lock"> </i>
                                    <input required=""
                                           type="password"
                                           placeholder="Password"
                                           defaultValue={visitorInfo.password}
                                           onChange={(e) => handleSetVisitorPassword(e)}
                                           onKeyPress={enterPressed.bind(this)} />

                                </label>
                                <p>{validator.message('password', visitorInfo.password, 'required')}</p>
                                {/*<div className="clearfix note mb-30">*/}
                                {/*    <a className="float-right" href="#">Forgot Password?</a>*/}
                                {/*</div>*/}
                                {/*                              <label className="checkbox fw-300">
                                  <input type="checkbox"
                                         name="checkbox-inline"
                                         checked={visitorInfo.keepLoggedIn}
                                         onChange={(e) => handleSetVisitorKeepLoggedIn(e)} />
                                      <i> </i> Keep me logged in
                              </label>*/}
                            </fieldset>
                            <footer>
                                {/*                              {!isValidForm() ? (
                                  <div>
                                      <p>Error Div.  Should only show when there are errors.</p>
                                  </div>
                              ) : null}*/}
                                {getMessages().firstName !== null ? (
                                    <div style={errorStyle}>
                                        <span>{getMessageForFirstName('firstName')}</span>
                                    </div>
                                ) : null}
                                {getMessages().lastName !== null ? (
                                    <div style={errorStyle}>
                                        <span>{getMessageForLastName('lastName')}</span>
                                    </div>
                                ) : null}
                                {getMessages().email !== null ? (
                                    <div style={errorStyle}>
                                        <span>{getMessageForEmail('email')}</span>
                                    </div>
                                ) : null}
                                {getMessages().password !== null ? (
                                    <div style={errorStyle}>
                                        <span>{getMessageForPassword('password')}</span>
                                    </div>
                                ) : null}
{/*                                {showVisitorLoginError ? (
                                    <div>
                                        Unauthorized
                                    </div>
                                ) : null }
                                <button type="button"
                                        className="btn btn-primary rad-0 float-right"
                                        onClick={handleLogin} ref={buttonRef} >
                                    <i className="fa fa-check"> </i> OK, LOG IN
                                </button>*/}
                            </footer>
                        </form>
                        {/*<div className="alert alert-mini alert-danger mb-30">*/}
                        {/*    <strong>Oh snap!</strong> Login Incorrect!*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default inject(({ store: { app }}) => ({
    visitorInfo: app.visitorInfo,
    updateVisitorFirstName: app.setVisitorFirstName,
    updateVisitorLastName: app.setVisitorLastName,
    updateVisitorEmail: app.setVisitorEmailAddress,
}))(observer(UserFirstVisitPage));
