import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useCookies } from 'react-cookie';
import { navigate } from "@reach/router";
//import _ from 'lodash';
import {
    Container,
    Image,
    Menu,
} from 'semantic-ui-react';
import Logo from '../../assets/images/logo_transparent.png';

const TopNavBar = props => {

    // eslint-disable-next-line no-unused-vars
    const sections = [
        'Home',
        'Connection Cards',
        'Children\'s Ministry',
        'Integration'
    ];

    const {
        visitorInfo,
        ministryUserInfo,
        updateVisitorEmail,
        updateVisitorPassword,
        updateVisitorLoginStatus,
        updateMinistryUserLoginStatus,
        updateMinistryUserEmail,
        updateMinistryUserPassword
    } = props;

    const [cookies, setCookie, removeCookie] = useCookies(['loginToken', 'keepLoggedIn']);

    const handleLogout = () => {
        removeCookie('keepLoggedIn');
        removeCookie('loginToken');
        updateVisitorLoginStatus(false);
        updateVisitorEmail('');
        updateVisitorPassword('');

        updateMinistryUserLoginStatus(false);
        updateMinistryUserEmail('');
        updateMinistryUserPassword('');
        navigate('/userlogin');
    };

    const handleMinistryUserLogout = () => {
        removeCookie('ministryloginToken');
        updateMinistryUserLoginStatus(false);
        updateMinistryUserEmail('');
        updateMinistryUserPassword('');
        navigateToUserLogin('ministrylogin');
    };

    // eslint-disable-next-line no-unused-vars
    const handleLogin = () => {
        navigate('/userlogin');
    };

    const navigateToHome = () => {
        navigate('/');
    };

    const navigateToUserLogin = () => {
      navigate('/userlogin');
    };

    const navigateToMinistryLogin = () => {
        navigate('/ministrylogin');
    };

    const navigateToUserInfo = () => {
        navigate('/userinfo');
    };

    const navigateToMinistryInfo = () => {
        navigate('/ministryinfo');
    };

    const menuStyle = {
        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        marginBottom: '1em',
        marginTop: '0',
        transition: 'box-shadow 0.5s ease, padding 0.5s ease',
    };

    const overlayMenuStyle = {
        position: 'relative',
        left: 0,
        transition: 'left 0.5s ease',
    };

    useEffect(() => {
        //let keepLoggedInCookie = cookies.keepLoggedIn;
        let loginTokenCookie = cookies.loginToken;
        let ministryLoginTokenCookie = cookies.ministryloginToken;
        // if (keepLoggedInCookie === undefined) {
        //     setCookie('keepLoggedInCookie', false);
        // }
        if (loginTokenCookie === undefined) {
            setCookie('loginTokenCookie', false);
        }
        if (ministryLoginTokenCookie === undefined) {
            setCookie('ministryLoginCookie', false);
        }
        if (loginTokenCookie === undefined || loginTokenCookie === null) {
            //navigate('/userlogin');
        }
        if (loginTokenCookie !== null && loginTokenCookie !== undefined)
        {
            updateVisitorLoginStatus(true);
        }
        if (ministryLoginTokenCookie !== null && ministryLoginTokenCookie !== undefined) {
            updateMinistryUserLoginStatus(true);
        }
    }, []);


    return(
        <React.Fragment>
            <Menu borderless
                  stackable
                  inverted
                  style={menuStyle}>
                <Container>
                    <Menu.Item>
                        <Image size='mini' src={Logo} /> ChurchContact
                    </Menu.Item>
                    <Menu.Item as='a' onClick={navigateToHome}>
                        <i className="fa fa-home"></i>&nbsp;&nbsp;Home
                    </Menu.Item>
                    {/*{!visitorInfo.visitorIsLoggedIn ? (*/}
                    {/*    <Menu.Item as='a' onClick={navigateToUserLogin}>*/}
                    {/*        <i className="fa fa-sign-in"></i>&nbsp;&nbsp;Member/Visitor Login*/}
                    {/*    </Menu.Item>*/}
                    {/*) : null}*/}
                    {!ministryUserInfo.ministryUserIsLoggedIn ? (
                        <Menu.Item as='a' onClick={navigateToMinistryLogin}>
                            <i className="fa fa-sign-in"></i>&nbsp;&nbsp;Ministry Login
                        </Menu.Item>
                    ) : null}
                    {visitorInfo.visitorIsLoggedIn && !ministryUserInfo.ministryUserIsLoggedIn ? (
                        <Menu.Item as='a' onClick={navigateToUserInfo}>
                            View Your Info
                        </Menu.Item>
                    ) : null}
                    {ministryUserInfo.ministryUserIsLoggedIn ? (
                        <Menu.Item as='a' onClick={navigateToMinistryInfo}>
                            View Ministry Info
                        </Menu.Item>
                    ) : null}
                    {ministryUserInfo.ministryUserIsLoggedIn ? (
                        <Menu.Item as='a' onClick={handleMinistryUserLogout}>
                            Logout
                        </Menu.Item>
                    ) : null}
                    {visitorInfo.visitorIsLoggedIn ? (
                        <Menu.Item as='a' onClick={handleLogout}>
                            Logout
                        </Menu.Item>
                    ) : null}
                </Container>
            </Menu>
        </React.Fragment>
    );
};

export default inject(({ store: { app }}) => ({
    visitorInfo: app.visitorInfo,
    ministryUserInfo: app.ministryUserInfo,
    updateVisitorEmail: app.setVisitorEmailAddress,
    updateVisitorPassword: app.setVisitorPassword,
    updateVisitorLoginStatus: app.setVisitorLoginStatus,
    updateMinistryUserEmail: app.setMinistryUserEmail,
    updateMinistryUserPassword: app.setMinistryUserPassword,
    updateMinistryUserLoginStatus: app.setMinistryUserLoginStatus
}))(observer(TopNavBar));
