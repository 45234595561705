import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useCookies } from 'react-cookie';
import { navigate } from '@reach/router';
import SimpleReactValidator from 'simple-react-validator';
import TopNavBar from '../../components/navbar';
import Logo from '../../assets/images/logo_transparent.png';

import {
    Button,
    Container,
    Form,
    Grid,
    Image,
    Segment
} from 'semantic-ui-react';

// function useForceUpdate() {
//     const [value, set] = useState(true);
//     return () => set(!value);
// }

const UserLoginPage = props => {
    let buttonRef = React.createRef();
    let validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});

    const {
        visitorInfo,
        updateVisitorEmail,
        updateVisitorPassword,
        // eslint-disable-next-line no-unused-vars
        updateVisitorKeepLoggedIn,
        updateVisitorLoginStatus,
        updateShowVisitorLoginError,
        performLogin,
        showVisitorLoginError
    } = props;

    const errorStyle = {
        color: 'red'
    };


    // function isValidForm() {
    //     //let messages = validator.getErrorMessages();
    //     //return messages.email === null && messages.password === null;
    // }

    function getMessages() {
        return validator.getErrorMessages();
    }

    function getMessageForEmail(fieldName) {
        var message = validator.getErrorMessages().email;
        return message;
    }

    function getMessageForPassword(fieldName) {
        var message = validator.getErrorMessages().password;
        return message;
    }

    function RenderLoginError(message) {
        //console.log('Login Message: ' + message);
        if (message === 'Unauthorized') {
            updateShowVisitorLoginError(true);
            //console.log(showVisitorLoginError);
        } else {
            updateShowVisitorLoginError(false);
        }
    }

    const [cookies, setCookie] = useCookies(['loginToken', 'keepLoggedIn']);
    //const { values, handleChange, handleSubmit } = useForm();

    //const forceUpdate = useForceUpdate();

    const handleSetVisitorEmail = (e) => {
        updateVisitorEmail(e.target.value);
    };

    const handleSetVisitorPassword = (e) => {
        updateVisitorPassword(e.target.value);
    };

    // const handleSetVisitorKeepLoggedIn = (e) => {
    //     const target = e.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     updateVisitorKeepLoggedIn(value);
    //     //console.log(visitorInfo.keepLoggedIn);
    // };
    //
    // const handleSetVisitorLoggedInStatus = (e) => {
    //
    // };

    const handleLogin = () => {
        //console.log(visitorInfo.keepLoggedIn);
        if (validator.allValid()) {
            var userData = {
                email: visitorInfo.email,
                password: visitorInfo.password,
                keepLoggedIn: visitorInfo.keepLoggedIn
            };
            let result = performLogin(userData);
            result.then((serverResponse) => {
                if (serverResponse.data !== undefined)
                {
                    //console.log(serverResponse.data.token);
                    RenderLoginError(serverResponse);
                    setCookie('loginToken', serverResponse.data.token);
                    //setCookie('keepLoggedIn', visitorInfo.keepLoggedIn);
                    updateVisitorLoginStatus(true);
                    navigate('/userinfo');
                } else {
                    //console.log('some kind of error');
                    console.log(serverResponse);
                    RenderLoginError(serverResponse);
                }
            }).catch((err) => {
                //console.log(err);
            });
        } else {
            //console.log('invalid form!');
            //let errors = validator.getErrorMessages();
            //console.log(errors);
            validator.showMessages();
            //this.forceUpdate();
            //forceUpdate();
        }

    };

    const enterPressed = (e) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            console.log(buttonRef);
            //buttonRef.onclick();
            buttonRef.current.click();
            //console.log('enter key pressed');
        }
    };

    const navigateToRegistrationInfo = () => {
        navigate('registrationinfo');
    };

    const navigateToForgotPasswordPage = () => {
        navigate('forgotpassword');
    };


    useEffect(() => {

        validator.purgeFields();
        // eslint-disable-next-line no-unused-vars,no-unused-vars
        let keepLoggedInCookie = cookies.keepLoggedIn;
        // eslint-disable-next-line no-unused-vars
        let loginTokenCookie = cookies.loginToken;

/*        if (keepLoggedInCookie === 'true' && loginTokenCookie !== null && loginTokenCookie !== undefined) {
            navigate('/userinfo');
        }*/


        // TODO: handle keepLoggedInCookie === false case
    }, []);


    //const classes = useStyles();

    return(
      <div>
          <TopNavBar />
          <Container>
              <Grid columns={2} divided>
                  <Grid.Row>
                      <Grid.Column>
                          <Image src={Logo} />
                      </Grid.Column>
                      <Grid.Column style={{ 'verticalAlign': 'middle'}}>
                          <Segment raised>
                              <h5>
                                  Please Login To View And Update Your Information
                              </h5>
                          </Segment>
                          <Form>
                              <Form.Field>
                                  <input
                                      type="email"
                                      placeholder="Email address"
                                      defaultValue={visitorInfo.email}
                                      onChange={(e) => handleSetVisitorEmail(e)} />
                                  {validator.message('email', visitorInfo.email, 'required|email')}
                              </Form.Field>
                              <Form.Field>
                                  <input
                                      type="password"
                                      placeholder="Password"
                                      defaultValue={visitorInfo.password}
                                      onChange={(e) => handleSetVisitorPassword(e)}
                                      onKeyPress={enterPressed.bind(this)} />
                                  <p>{validator.message('password', visitorInfo.password, 'required')}</p>
                              </Form.Field>
                              <footer>
                                  {getMessages().email !== null ? (
                                      <div style={errorStyle}>
                                          <span>{getMessageForEmail('email')}</span>
                                      </div>
                                  ) : null}
                                  {getMessages().password !== null ? (
                                      <div style={errorStyle}>
                                          <span>{getMessageForPassword('password')}</span>
                                      </div>
                                  ) : null}
                                  {showVisitorLoginError ? (
                                      <div style={errorStyle}>
                                          Something went wrong.  If you have previously registered try again or click the 'Forgot Password' link below to reset your password.
                                          If you haven't registered previously, click <a onClick={navigateToRegistrationInfo} href="javascript:void(0);">here</a> for more info about registration)
                                      </div>
                                  ) : null }
                                  <br />
                                  <div className="clearfix note mb-30">
                                      <a className="float-right" href="javascript:void(0);" onClick={navigateToForgotPasswordPage}>Forgot Password?</a>
                                  </div>
                                  <br />
                                  <Button type="button"
                                          className="ui primary button"
                                          disabled={!validator.allValid()}
                                          onClick={handleLogin} ref={buttonRef} >
                                      <i className="fa fa-check"> </i>&nbsp; OK, LOG IN
                                  </Button>
                              </footer>
                          </Form>
                      </Grid.Column>
                  </Grid.Row>
              </Grid>

          </Container>
      </div>
    );
};

export default inject(({ store: { app }}) => ({
    visitorInfo: app.visitorInfo,
    updateVisitorEmail: app.setVisitorEmailAddress,
    updateVisitorPassword: app.setVisitorPassword,
    updateVisitorKeepLoggedIn: app.setVisitorKeepLoggedIn,
    updateVisitorLoginStatus: app.setVisitorLoginStatus,
    updateShowVisitorLoginError: app.setShowVisitorLoginError,
    performLogin: app.loginVisitor,
    showVisitorLoginError: app.showVisitorLoginError
}))(observer(UserLoginPage));
