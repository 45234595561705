import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Container,  Form,  Item } from "semantic-ui-react";
import _ from 'lodash';
//import NumericInput from 'react-numeric-input';
import KidInfo from "./kidinfo";
//import TopNavBar from '../../../components/navbar';


const KidSignUp = props => {
    // const columns = _.times(4, (i) => (
    //     <Grid.Column key={i}>
    //         {i}
    //     </Grid.Column>
    // ));
    const [currentKid] = useState({kidNumber: '', firstName: '', lastName: '', age: 0});
    const [kidAdded, setKidAdded] = useState(false);
    const [parentInfo, setParentInfo] = useState({id: '', firstName: '', lastName: '', email: '', mobilePhone: ''});
    const {
        addKid,
        visitorInfo,
        getVisitor
    } = props;

    const updateParentInfo = (info) => {
        setParentInfo(() => {
            parentInfo.id = info.id;
            parentInfo.firstName = info.firstName;
            parentInfo.lastName = info.lastName;
            parentInfo.email = info.email;
            parentInfo.mobilePhone = info.mobilePhone;
        });

        //console.log(parentInfo);
    };

    const handleAddKid = () => {
        //console.log(currentKid);
        let newKid = new KidInfo(currentKid.kidNumber, currentKid.firstName, currentKid.lastName, currentKid.age);
        //console.log(newKid);
        addKid(newKid);
        setKidAdded(true);
    };

    const handleChangeNumberChange = (e) => {
        currentKid.kidNumber = e.target.value;
    };

    const handleChangeFirstName = (e) => {
        currentKid.firstName = e.target.value;
    };

    const handleChangeLastName = (e) => {
        currentKid.lastName = e.target.value;
    };

    const handleChangeAge = (e) => {
        currentKid.age = e.target.value;
    };

    useEffect(() => {

        let pid = props.parentId;
        let visitorPromise = getVisitor(pid);
        visitorPromise.then((data) => {
            if (data.data !== undefined) {
                //console.log(data.data);
                updateParentInfo(data.data);
            }
        }).catch((e) => console.log(e));
    }, [visitorInfo]);

    return(
        <React.Fragment>
            <Container>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Input placeholder="Child Number"
                                    defaultValue={currentKid.kidNumber}
                                    onChange={(e) => handleChangeNumberChange(e)}
                                    readOnly={kidAdded} />

                        <Form.Input placeholder="First Name"
                                    defaultValue={currentKid.firstName}
                                    onChange={(e) => handleChangeFirstName(e)}
                                    readOnly={kidAdded} />

                        <Form.Input placeholder="Last Name"
                                    defaultValue={currentKid.lastName}
                                    onChange={(e) => handleChangeLastName(e)}
                                    readOnly={kidAdded} />

                        <Form.Input type='number' placeholder="Age"
                                    defaultValue={currentKid.age}
                                    onChange={(e) => handleChangeAge(e)}
                                    readOnly={kidAdded} />

                        <Button className='ui button primary' onClick={handleAddKid}>Add</Button>
                    </Form.Group>
                    {kidAdded ? (
                        <Item>
                            <Item.Content>
                                <p>{currentKid.firstName} {currentKid.lastName}, Age({currentKid.age}) to be added.</p>
                            </Item.Content>
                        </Item>
                    ) : null}
                </Form>
            </Container>
        </React.Fragment>
    );
};

export default inject(({ store: { app }}) => ({
    addKid: app.addKid,
    visitorInfo: app.visitorInfo,
    getVisitor: app.getVisitor
}))(observer(KidSignUp));
