import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../components/navbar';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
// import ReactPhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/dist/style.css'
import {navigate} from "@reach/router";
import Dialog from '@material-ui/core/Dialog';

import {
    Button,
    Container,
    Divider,
    Form
} from 'semantic-ui-react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


let ContactCardPage;
ContactCardPage = props => {

    //const baseUrl = "https://localhost:5001/";
    //const baseUrl = "http://api.churchcontact.org/";
    //const baseUrl = "http://api.cc.giwutechapps.com/";
    let validator = new SimpleReactValidator({autoForceUpdate: this});

    const {
        ministryName,
        getVisitor,
        visitorInfo,
        ministryInfo,
        getMinistry,
        // eslint-disable-next-line no-unused-vars
        updateVisitorId,
        updateMinistryName,
        updateMinistryWebsiteLink,
        updateMinistryRedirectLink,
        updateVisitorMobilePhone,
        updateVisitorFirstName,
        updateVisitorLastName,
        updateVisitorEmail,
        // eslint-disable-next-line no-unused-vars
        updateVisitorZipCode,
        updateVisitorPassword,
        updateVisitorData
    } = props;

    // const cardStyle = {
    //     width: '30rem',
    //     display: 'flex'
    // };

    const [open, setOpen] = useState(false);
    const [openReg, setOpenReg] = useState(false);

    const handleClickClose = () => {
        setOpen(false);
        let redirectLink = ministryInfo.redirectLink;
        navigate(redirectLink);
    };

    const handleClickCloseReg = () => {
      setOpenReg(true);
        let redirectLink = ministryInfo.redirectLink;
        navigate(redirectLink);
    };

    const errorStyle = {
        color: 'red'
    };

    const setMinistryName = (name) => {
        updateMinistryName(name);
    };

    const setMinistryWebsiteLink = (link) => {
        updateMinistryWebsiteLink(link);
    };

    const setMinistryRedirectLink = (link) => {
        updateMinistryRedirectLink(link);
    };

    const setVisitorMobilePhone = (phone) => {
        updateVisitorMobilePhone(phone);
    };

    // const setVisitorFirstName = (name) => {
    //   updateVisitorFirstName(name);
    // };
    //
    // const setVisitorLastName = (name) => {
    //   updateVisitorLastName(name);
    // };
    //
    // const setVisitorEmail = (email) => {
    //     updateVisitorEmail(email);
    // };
    //
    // const setVisitorId = (id) => {
    //     updateVisitorId(id);
    // };

    const handleSetVisitorMobilePhone = (e) => {
        //console.log(e.target.value);
        //updateVisitorMobilePhone(e.target.value);
    };

    const handleSetVisitorFirstName = (e) => {
        //console.log(e.target.value);
        updateVisitorFirstName(e.target.value);
    };

    const handleSetVisitorLastName = (e) => {
        updateVisitorLastName(e.target.value);
    };

    // const handleSetVisitorZipCode = (e) => {
    //     updateVisitorZipCode(e.target.value);
    // };

    const handleSetVisitorEmail = (e) => {
        updateVisitorEmail(e.target.value);
    };

    const handleSetVisitorPassword = (e) => {
        updateVisitorPassword(e.target.value);
    };

    const [passwordsMatch, setPasswordsMatch] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const handlePasswordMatch = (e) => {
        if (visitorInfo.password !== e.target.value) {
            console.log(e.target.value);
            setPasswordsMatch({passwordsMatch: false})
        } else {
            setPasswordsMatch({passwordsMatch: true})
        }
    };

    const handleUpdateUserData = () => {
        //console.log(ministryName);
        var userData = {
            id: props.visitorId,
            firstName: visitorInfo.firstName,
            lastName: visitorInfo.lastName,
            email: visitorInfo.email,
            password: visitorInfo.password,
            zipCode: visitorInfo.zipCode,
            mobilePhone: visitorInfo.mobilePhone,
            orgName: ministryName
        };
        let visitorUpdatePromise = updateVisitorData(userData);
        visitorUpdatePromise.then((data) => {
           if (data.success === true)
           {
               setOpenReg(true);
           }
        });
    };

    function getMessages() {
        return validator.getErrorMessages();
    }

    function getMessageForEmail(fieldName) {
        var message = validator.getErrorMessages().email;
        return message;
    }

    function getMessageForPassword(fieldName) {
        var message = validator.getErrorMessages().password;
        return message;
    }

    useEffect(() => {
        let ministryPromise = getMinistry(props.orgId);
        ministryPromise.then((data) => {
            if (data !== undefined) {
                setMinistryName(data.data.ministryName);
                setMinistryRedirectLink(data.data.redirectLink);
            }
        });

        let visitorPromise = getVisitor(props.visitorId);
        visitorPromise.then((data) => {
            if (data.data !== undefined) {
                if (data.data.isRegistered === true) {
                    setOpen(true);
                } else {
                    setVisitorMobilePhone(data.data.mobilePhone);
                }
            }
        });
    }, []);


    return (
        <React.Fragment>
            <TopNavBar/>
            <Form>
                <Container>
                    <h1>{ministryName}</h1>
                    <h3>
                        <b>First Time Visitor Connection Card Registration</b>
                    </h3>
                    <Divider />

                        <Form.Field>
                            <input type="text"
                                       className="form-control"
                                       id="txtMobilePhone"
                                       name="mobilePhone"
                                       placeholder="Mobile Phone"
                                       value={visitorInfo.mobilePhone}
                                       readOnly={true}
                                       onChange={(e) => handleSetVisitorMobilePhone(e)}/>
                        </Form.Field>
                    {/*<Form.Field>*/}
                    {/*    <ReactPhoneInput*/}
                    {/*        defaultCountry={'us'}*/}
                    {/*        onlyCountries={['us']}*/}
                    {/*        value={visitorInfo.mobilePhone}*/}
                    {/*        onChange={(e) => handleSetVisitorMobilePhone(e)} />*/}
                    {/*</Form.Field>*/}
                        <Form.Field>
                            <input type="text"
                                       className="form-control"
                                       id="txtFirstName"
                                       name="firstName"
                                       placeholder="First Name"
                                       value={visitorInfo.firstName}
                                       onChange={(e) => handleSetVisitorFirstName(e)}/>
                            {validator.message('firstName', visitorInfo.firstName, 'required|alpha')}
                        </Form.Field>
                        <Form.Field>
                            <input type="text"
                                       className="form-control"
                                       id="txtLastName"
                                       name="lastName"
                                       placeholder="Last Name"
                                       value={visitorInfo.lastName}
                                       onChange={(e) => handleSetVisitorLastName(e)}/>
                            {validator.message('lastName', visitorInfo.lastName, 'required|alpha')}
                        </Form.Field>
                        <Form.Field>
                            <input type="text"
                                       className="form-control"
                                       id="txtEmail"
                                       name="email"
                                       placeholder="Email Address"
                                       value={visitorInfo.email}
                                       onChange={(e) => handleSetVisitorEmail(e)}/>
                            {validator.message('email', visitorInfo.email, 'required|email')}
                        </Form.Field>
                        {/*<Form.Field>*/}
                        {/*    <input type="password" className=" form-control"*/}
                        {/*               id="txtPassword"*/}
                        {/*               name="password"*/}
                        {/*               placeholder="Password"*/}
                        {/*               value={visitorInfo.password}*/}
                        {/*               onChange={(e) => handleSetVisitorPassword(e)}/>*/}
                        {/*    {validator.message('password', visitorInfo.password, 'required|alpha_num_dash')}*/}
                        {/*</Form.Field>*/}
                    <br/>
                    <footer>
                        {getMessages().email !== null ? (
                            <div style={errorStyle}>
                                <span>{getMessageForEmail('email')}</span>
                            </div>
                        ) : null}
                        {getMessages().password !== null ? (
                            <div style={errorStyle}>
                                <span>{getMessageForPassword('password')}</span>
                            </div>
                        ) : null}
                        {passwordsMatch ? (
                            <div style={errorStyle}>
                                <span>Passwords must match</span>
                            </div>
                        ) : null}
                    </footer>
                    <Button type="button"
                            className="ui primary button"
                            disabled={!validator.allValid()}
                            onClick={handleUpdateUserData}>
                        Connect
                    </Button>
                    <Dialog open={open} onClose={handleClickClose}>
                        <DialogTitle id="responsive-dialog-title">{"Success"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You've already connected as a first time guest.  Redirecting you to the church's website.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button  className="ui primary button"  onClick={handleClickClose}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openReg} onClose={handleClickCloseReg}>
                        <DialogTitle id="responsive-dialog-title">{"Success"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Thanks for connecting.  We've received your information.  Redirecting you to the church's website.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button  className="ui primary button"  onClick={handleClickCloseReg}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </Form>
        </React.Fragment>
    );
};

export default inject(({ store: { app }}) => ({
    ministryName: app.ministryName,
    //visitorId: app.visitorId,
    getVisitor: app.getVisitor,
    getMinistry: app.getMinistry,
    visitorInfo: app.visitorInfo,
    ministryInfo: app.ministryInfo,
    visitorMobilePhone: app.visitorMobilePhone,
    visitorFirstName: app.visitorFirstName,
    visitorLastName: app.visitorLastName,
    visitorZipCode: app.visitorZipCode,
    visitorEmailAddress: app.visitorEmailAddress,
    updateVisitorId: app.setVisitorId,
    updateMinistryName: app.setMinistryName,
    updateVisitorMobilePhone: app.setVisitorMobilePhone,
    updateVisitorFirstName: app.setVisitorFirstName,
    updateVisitorLastName: app.setVisitorLastName,
    updateVisitorEmail: app.setVisitorEmailAddress,
    updateVisitorZipCode: app.setVisitorZipCode,
    updateVisitorPassword: app.setVisitorPassword,
    updateVisitorData: app.updateVisitorReg,
    updateMinistryWebsiteLink: app.setMinistryWebsiteLink,
    updateMinistryRedirectLink: app.setMinistryRedirectLink
}))(observer(ContactCardPage));
