import React from "react";
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../../components/navbar';
//import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
//import Footer from "../../../components/footer";
import { Player } from 'video-react';
import SmsVideo from '../../../assets/video/SMSLink.mp4';

const ContactCardLandingPage = props => {
    return (
        <div>
            <TopNavBar />
            <CssBaseline />

            <Container maxWidth="lg">
                <h3>Connection Cards</h3>
                <div className="heading-title heading-border heading-color mt-0">
                    <h2 className="fs-18 fw-400">Making it easy to connect with people</h2>
                    {/*<p className="fs-13">Making it easy to connect.</p>*/}
                </div>

                <Typography>
                    From now on, engage with your visitors and members a whole new way.
                </Typography>
                <br />
                <Typography>
                    ChurchContact makes it easy for people to utilize a smartphone to connect with your ministry.
                </Typography>
                <br />
                <Typography>
                    No more paper based connection cards.  We will help your ministry modernize connecting with people.
                </Typography>
                <hr />
                <Player fluid={false} width="360" src={SmsVideo} autoPlay={true} />
            </Container>

        </div>
    )
};

export default inject(({ store: {app}}) => ({

}))(observer(ContactCardLandingPage));
