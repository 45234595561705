import React, { useState } from 'react';
import { navigate } from '@reach/router';
import SimpleReactValidator from 'simple-react-validator';
import TopNavBar from '../../components/navbar';
import Logo from '../../assets/images/logo_transparent.png';
import {
    Button,
    Container,
    Form,
    Grid,
    Image,
    Modal,
    Segment
} from 'semantic-ui-react';
import {inject, observer} from "mobx-react";

const ForgotPassword = (props) => {
    const {
        visitorInfo,
        resetUserPassword,
        updateVisitorEmail
    } = props;

    const [modalOpen, setModal] = useState(false);
    const [errorModalOpen, setErrorModal] = useState(false);

    const errorStyle = {
        color: 'red'
    };

    const handleSetVisitorEmail = (e) => {
        updateVisitorEmail(e.target.value);
    };

    const navigateToLogin = () => {
        navigate('userlogin');
    };

    const closeErrorModal = () => {
        setErrorModal(false);
    };

    const handleResetPassword = (e) => {
        let result = resetUserPassword(visitorInfo.email);
        result.then((serverResponse) => {
            if (serverResponse.data !== undefined && serverResponse.data.success === true)
            {
                setModal(true);
            } else {
                setErrorModal(true);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    function getMessages() {
        return validator.getErrorMessages();
    }

    function getMessageForEmail(fieldName) {
        var message = validator.getErrorMessages().email;
        return message;
    }

    let buttonRef = React.createRef();
    let modalRef = React.createRef();
    let validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});

    return (
        <React.Fragment>
            <TopNavBar />
            <Container>
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Image src={Logo} />
                        </Grid.Column>
                        <Grid.Column style={{ 'verticalAlign': 'middle'}}>
                            <Segment raised>
                                <h5>
                                    Please provide your email address in order to reset your password
                                </h5>
                            </Segment>
                            <Form>
                                <Form.Field>
                                    <input
                                        type="email"
                                        placeholder="Email address"
                                        defaultValue={visitorInfo.email}
                                        onChange={(e) => handleSetVisitorEmail(e)} />
                                    {validator.message('email', visitorInfo.email, 'required|email')}
                                </Form.Field>
                                <footer>
                                    {getMessages().email !== null ? (
                                        <div style={errorStyle}>
                                            <span>{getMessageForEmail('email')}</span>
                                        </div>
                                    ) : null}
                                    <br />
                                    <Button type="button"
                                            className="ui primary button"
                                            disabled={!validator.allValid()}
                                            onClick={handleResetPassword} ref={buttonRef} >
                                        <i className="fa fa-check"> </i>&nbsp; Reset Password
                                    </Button>
                                    {/*<VisitorForgotPasswordModal  />*/}
                                    <Modal size="tiny" open={modalOpen}>
                                        <Modal.Header>
                                            Check you email and follow the link to reset your password.
                                        </Modal.Header>
                                        <Modal.Content>
                                            <p>Press Continue To Return the Login Page</p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button positive icon='checkmark' labelPosition='right' content='Continue' onClick={navigateToLogin} />
                                        </Modal.Actions>
                                    </Modal>

                                    <Modal size="tiny" open={errorModalOpen}>
                                        <Modal.Header>
                                            Email Address Not Found
                                        </Modal.Header>
                                        <Modal.Content>
                                            <p>The email address entered is not a registered email.  Check the email address to ensure it is correct.</p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button positive icon='checkmark' labelPosition='right' content='Continue' onClick={closeErrorModal} />
                                        </Modal.Actions>
                                    </Modal>
                                </footer>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default inject(({ store: { app }}) => ({
    visitorInfo: app.visitorInfo,
    resetUserPassword: app.resetUserPassword,
    updateVisitorEmail: app.setVisitorEmailAddress
}))(observer(ForgotPassword));

