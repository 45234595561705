import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../components/navbar';
import { useCookies } from 'react-cookie';
//import { Modal } from 'react-bootstrap';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
//import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
//import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import useMediaQuery from '@material-ui/core/useMediaQuery';

const UserInfoPage = props => {

    const {
        visitorInfo,
        getVisitorInformation,
        updateVisitorId,
        updateVisitorMobilePhone,
        updateVisitorFirstName,
        updateVisitorLastName,
        updateVisitorEmail,
        updateVisitorZipCode,
        updateVisitorAddress,
        updateVisitorAddress2,
        updateVisitorCity,
        updateVisitorState,
        // eslint-disable-next-line no-unused-vars
        updateVisitorPassword,
        updateVisitorData
    } = props;

    const useStyles = makeStyles(theme => ({
        appBar: {
            position: 'relative',
        },
        layout: {
            width: 'auto',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
                width: 600,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        paper: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            padding: theme.spacing(2),
            [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
                marginTop: theme.spacing(6),
                marginBottom: theme.spacing(6),
                padding: theme.spacing(3),
            },
        },
        stepper: {
            padding: theme.spacing(3, 0, 5),
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        button: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
        },
    }));

    // const setVisitorId = (id) => {
    //     updateVisitorId(id);
    // };
    //
    // const setVisitorMobilePhone = (phone) => {
    //     updateVisitorMobilePhone(phone);
    // };
    //
    // const setVisitorFirstName = (name) => {
    //     updateVisitorFirstName(name);
    // };
    //
    // const setVisitorLastName = (name) => {
    //     updateVisitorLastName(name);
    // };
    //
    // const setVisitorEmail = (email) => {
    //     updateVisitorEmail(email);
    // };
    //
    // const setVisitorZip = (zip) => {
    //     updateVisitorZipCode(zip);
    // };

    const handleSetVisitorMobilePhone = (e) => {
        //console.log(e.target.value);
        updateVisitorMobilePhone(e.target.value);
    };

    const handleSetVisitorFirstName = (e) => {
        updateVisitorFirstName(e.target.value);
    };

    const handleSetVisitorLastName = (e) => {
        updateVisitorLastName(e.target.value);
    };

    const handleSetVisitorAddress = (e) => {
        updateVisitorAddress(e.target.value);
    };

    const handleSetVisitorAddress2 = (e) => {
        updateVisitorAddress2(e.target.value);
    };

    const handleSetVisitorCity = (e) => {
        updateVisitorCity(e.target.value);
    };

    const handleSetVisitorState = (e) => {
        updateVisitorState(e.target.value);
    };

    const handleSetVisitorZipCode = (e) => {
        updateVisitorZipCode(e.target.value);
    };

    const handleSetVisitorEmail = (e) => {
        updateVisitorEmail(e.target.value);
    };

    // const handleSetVisitorPassword = (e) => {
    //     updateVisitorPassword(e.target.value);
    // };

    const handleUpdateUserData = () => {
        console.log(visitorInfo.city);
        var userData = {
            id: visitorInfo.id,
            firstName: visitorInfo.firstName,
            lastName: visitorInfo.lastName,
            email: visitorInfo.email,
            password: visitorInfo.password,
            address: visitorInfo.address,
            address2: visitorInfo.address2,
            city: visitorInfo.city,
            state: visitorInfo.state,
            zipCode: visitorInfo.zipCode,
            mobilePhone: visitorInfo.mobilePhone
        };
        let res = updateVisitorData(userData);
        res.then((serverResponse) => {
           //console.log('Server Response is : ' + serverResponse);
            //handleShow();
            handleClickOpen();
        });
    };

    // const cardStyle = {
    //     width: '50rem',
    //     display: 'flex'
    // };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const [cookies] = useCookies(['loginToken', 'keepLoggedIn']);
    //const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
       // send bearer token to backend to retrieve user specific info
        let loginTokenCookie = cookies.loginToken;
        let result = getVisitorInformation(loginTokenCookie);
        //console.log(result);

        handleSetVisitorAddress.bind(this);
        handleSetVisitorAddress2.bind(this);
        handleSetVisitorCity.bind(this);
        handleSetVisitorState.bind(this);
        handleSetVisitorZipCode.bind(this);

        result.then((data) => {
           //console.log(data);
            if (data.data !== undefined) {
                updateVisitorId(data.data.id);
                updateVisitorMobilePhone(data.data.mobilePhone);
                updateVisitorFirstName(data.data.firstName);
                updateVisitorLastName(data.data.lastName);
                updateVisitorEmail(data.data.email);
                updateVisitorAddress(data.data.address);
                updateVisitorAddress2(data.data.address2);
                updateVisitorCity(data.data.city);
                updateVisitorState(data.data.state);
                updateVisitorZipCode(data.data.zipCode);
            }

        });
    }, []);

    const classes = useStyles();

    return(
        <React.Fragment>
            <TopNavBar />
            <CssBaseline />

            <main className={classes.layout}>
                <form>
                    <Typography variant="h6" gutterBottom>
                        <b>User Info for {visitorInfo.firstName} {visitorInfo.lastName}</b>
                    </Typography>
                    <hr />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField type="text"
                                   className="form-control"
                                   id="txtMobilePhone"
                                   name="mobilePhone"
                                   placeholder="Mobile Phone"
                                   defaultValue={visitorInfo.mobilePhone}
                                   readOnly={true}
                                   onChange={(e) => handleSetVisitorMobilePhone(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                   className="form-control"
                                   id="txtFirstName"
                                   name="firstName"
                                   placeholder="First Name"
                                   defaultValue={visitorInfo.firstName}
                                   onChange={(e) => handleSetVisitorFirstName(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                   className="form-control"
                                   id="txtLastName"
                                   name="lastName"
                                   placeholder="Last Name"
                                   defaultValue={visitorInfo.lastName}
                                   onChange={(e) => handleSetVisitorLastName(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                   className="form-control"
                                   id="txtAddress"
                                   name="address"
                                   placeholder="Address"
                                   defaultValue={visitorInfo.address}
                                   onChange={(e) => handleSetVisitorAddress(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                   className="form-control"
                                   id="txtApt"
                                   name="address2"
                                   placeholder="Apt No."
                                   defaultValue={visitorInfo.address2}
                                   onChange={(e) => handleSetVisitorAddress2(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                       className="form-control"
                                       id="txtCity"
                                       name="city"
                                       placeholder="City"
                                       defaultValue={visitorInfo.city}
                                       onChange={(e) => handleSetVisitorCity(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                       className="form-control"
                                       id="txtState"
                                       name="state"
                                       placeholder="State"
                                       defaultValue={visitorInfo.state}
                                       onChange={(e) => handleSetVisitorState(e)} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                   className="form-control"
                                   id="txtZip"
                                   name="zip"
                                   placeholder="Zip Code"
                                   defaultValue={visitorInfo.zipCode}
                                   onChange={(e) => handleSetVisitorZipCode(e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="text"
                                   className="form-control"
                                   id="txtEmail"
                                   name="email"
                                   placeholder="Email Address"
                                   defaultValue={visitorInfo.email}
                                   onChange={(e) => handleSetVisitorEmail(e)} />
                        </Grid>
                    </Grid>
                    <hr />
                    <Button type="button"
                            className={classes.submit}
                            color="blue"
                            onClick={handleUpdateUserData}>
                        Update
                    </Button>

                    <Dialog open={open} onClose={handleClickClose}>
                        <DialogTitle id="responsive-dialog-title">{"Success"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You're data was updated!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button color="blue" onClick={handleClickClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </main>
        </React.Fragment>
    );
};

export default inject(({ store: { app }}) => ({
    getVisitorInformation: app.getUserInfo,
    visitorInfo: app.visitorInfo,
    updateVisitorId: app.setVisitorId,
    updateVisitorMobilePhone: app.setVisitorMobilePhone,
    updateVisitorFirstName: app.setVisitorFirstName,
    updateVisitorLastName: app.setVisitorLastName,
    updateVisitorEmail: app.setVisitorEmailAddress,
    updateVisitorZipCode: app.setVisitorZipCode,
    updateVisitorAddress: app.setVisitorAddress,
    updateVisitorAddress2: app.setVisitorAddress2,
    updateVisitorCity: app.setVisitorCity,
    updateVisitorState: app.setVisitorState,
    updateVisitorPassword: app.setVisitorPassword,
    updateVisitorData: app.updateVisitorData
}))(observer(UserInfoPage));
