import { observable } from "mobx";

const VisitorInfo = observable({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    password: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    keepLoggedIn: false,
    visitorIsLoggedIn: false,

    constructor(id, firstName, lastName, email, mobilePhone) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.mobilePhone = mobilePhone;
    }
});

export default VisitorInfo;
