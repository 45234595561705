import React, {useEffect, useState} from 'react';
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../components/navbar';
import { useCookies } from 'react-cookie';
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import moment from 'moment';
import {
    //Button,
    Container,
    Divider,
    //Form,
    //Grid,
    //Image,
    //Modal,
    Segment
} from 'semantic-ui-react';


const MinistryInfoPage = props => {
    const {
        ministryInfo,
        getMinistryVisitors,
        // eslint-disable-next-line no-unused-vars
        getMinistryInfo,
        setMinistryName
    } = props;

    const [rowData, setRowData] = useState('');
    const handleSetRowData = (data) => setRowData(data);

    let columnDefs;
    columnDefs = [
        {
            headerName: "First Name", field: "firstName", sortable: true, resizable: true, headerCheckboxSelection: true
        },
        {
            headerName: "Last Name", field: "lastName", sortable: true, resizable: true
        },
        {
            headerName: "Email", field: "email", sortable: true, resizable: true
        },
        {
            headerName: "Phone Number", field: "mobilePhone", sortable: true, resizable: true,
            cellRenderer: (data) => {
                let phoneNumberString = formatPhoneNumber(data.value.slice(1));
                return phoneNumberString;
            }
        },
        {
            headerName: "Address", field: "address", sortable: true, resizable: true
        },
        {
            headerName: "City", field: "city", sortable: true, resizable: true
        },
        {
            headerName: "State", field: "state", sortable: true, resizable: true
        },
        {
            headerName: "Date of First Visit", field: "dateOfFirstVisit", sortable: true, resizable: true,
            cellRenderer: (data) => {
                return moment(data.value).format('MM/DD/YYYY HH:mm')
            }
        }
    ];


    const [cookies] = useCookies(['ministryloginToken', 'keepLoggedIn']);

    useEffect(() => {
        let loginTokenCookie = cookies.ministryloginToken;
        let result = getMinistryVisitors(loginTokenCookie);

        result.then((data) => {
            console.log(data.data);
            handleSetRowData(data.data);
        });

        let ministryInfoResult = getMinistryInfo(loginTokenCookie);
        ministryInfoResult.then((data) => {
            console.log(data.data);
            if (data.data !== undefined){
                setMinistryName(data.data.ministryName);
            }
        });

    }, []);

    const formatPhoneNumber = (phoneNumberString) => {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    };

    const onGridReady = (params) => {
        console.log(params);
        params.api.sizeColumnsToFit();
    };

    const onRowSelected = (e) => {
      //console.log(e.node);
    };

    return (
        <div>
            <TopNavBar />
            <Container fluid style={{padding: '10px'}}>
                <h3>Ministry Info Page for {ministryInfo.ministryName}</h3>
                <Divider />
                <Segment>
                    Visitor List
                </Segment>
                <div className="ag-theme-balham" style={{height: '400px', width: 'auto'}}>
                    <AgGridReact columnDefs={columnDefs}
                                 rowData={rowData}
                                 onRowSelected={(e) => onRowSelected(e)}
                                 onGridReady={(e) => onGridReady(e)}
                                 rowSelection="multiple"
                                 pagination="true"
                                 paginationPageSize="10"
                                 animateRows>

                    </AgGridReact>
                </div>
            </Container>

        </div>
    );
};

export default inject(({ store: { app }}) => ({
    ministryInfo: app.ministryInfo,
    getMinistryVisitors: app.getMinistryVisitors,
    getMinistryInfo: app.getMinistryInfo,
    setMinistryName: app.setMinistryInfoName
}))(observer(MinistryInfoPage));
