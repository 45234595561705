import React from 'react';
import Background from '../../assets/images/audience-blue2.jpg';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';

import Container from '@material-ui/core/Container';

const TopSlider = props => {

        const useStyles = makeStyles(theme => ({
            toolbar: {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            toolbarTitle: {
                flex: 1,
            },
            toolbarSecondary: {
                justifyContent: 'space-between',
                overflowX: 'auto',
            },
            toolbarLink: {
                padding: theme.spacing(1),
                flexShrink: 0,
            },
            mainFeaturedPost: {
                position: 'relative',
                backgroundColor: theme.palette.grey[800],
                color: theme.palette.common.white,
                marginBottom: theme.spacing(4),
                backgroundImage: `url(${Background})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            },
            overlay: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                backgroundColor: 'rgba(0,0,0,.3)',
            },
            mainFeaturedPostContent: {
                position: 'relative',
                padding: theme.spacing(3),
                [theme.breakpoints.up('md')]: {
                    padding: theme.spacing(6),
                    paddingRight: 0,
                },
            },
            mainGrid: {
                marginTop: theme.spacing(3),
            },
            card: {
                display: 'flex',
            },
            cardDetails: {
                flex: 1,
            },
            cardMedia: {
                width: 160,
            },
            markdown: {
                ...theme.typography.body2,
                padding: theme.spacing(3, 0),
            },
            sidebarAboutBox: {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.grey[200],
            },
            sidebarSection: {
                marginTop: theme.spacing(3),
            },
            footer: {
                backgroundColor: theme.palette.background.paper,
                marginTop: theme.spacing(8),
                padding: theme.spacing(6, 0),
            },
        }));


        const classes = useStyles();

        return(
            <React.Fragment>
                <CssBaseline />
                <Container maxWidth="lg">
                    <Paper className={classes.mainFeaturedPost}>
                        {/* Increase the priority of the hero background image */}
                        {
                            <img
                                style={{ display: 'inline-block', height: '300px' }}
                                src="../../assets/images/11-min.jpg"
                                alt=""
                            />
                        }
                        <div className={classes.overlay} />
                    </Paper>
                </Container>
            </React.Fragment>
        );
};

export default TopSlider;
