import { observable } from 'mobx';

const MinistryInfo = observable({
    id: '',
    ministryName: '',
    smsPhoneNumber: '',
    taxIdNumber: '',
    ministryCode: '',
    websiteLink: '',
    redirectLink: ''
});

export default MinistryInfo;
