import React from 'react';
import './App.css';
import { Router } from '@reach/router';
import ReactGA from 'react-ga';
//import { BrowserRouter as Router, Route, Link } from "react-router-dom";
//import createHashSource from 'hash-source';
import { CookiesProvider } from "react-cookie";
import HomePage from './routes/home';
import ContactCardLandingPage from './routes/contactcard/landingpage';
import ChildrensMinistryLandingPage from './routes/kids/landingpage';
import IntegrationLandingPage from './routes/integration/landingpage';
import ContactCardPage from './routes/contactcard';
import MinistryLoginPage from "./routes/ministrylogin";
import UserLoginPage from "./routes/userlogin";
import UserCreatePasswordPage from "./routes/usercreatepassword";
import MinistryCreatePage from "./routes/ministrycreate";
import KidsRegistrationPage from "./routes/kids/kidsregistration";
import UserInfoPage from "./routes/userinfo";
import MinistryInfoPage from "./routes/ministryinfo";
import UserFirstVisitPage from "./routes/userfirst";
import RegistrationInfo from "./routes/registrationinfo";
import ForgotPassword from "./routes/forgotpassword";
import ResetVisitorPassword from "./routes/pwdreset/user";
//let source = createHashSource();

//let history = createHistory(source);
function App() {
    initializeReactGA();
  return (
    <div className="smoothscroll enable-animation">
        {/*<LocationProvider history={history}>*/}
            <CookiesProvider>
              <Router>
                  {/*<Route path="/" component={HomePage} />*/}
                  {/*<Route path="/connectioncardlanding" component={ContactCardLandingPage} />*/}
                  {/*<Route path="/kidslanding" component={ChildrensMinistryLandingPage} />*/}
                  {/*<Route path="/integrationlanding" component={IntegrationLandingPage} />*/}

                  <HomePage path='/' />
                  <HomePage path=':orgId/:visitorId' />
                  <ContactCardLandingPage path='connectioncardlanding' />
                  <ChildrensMinistryLandingPage path='kidslanding' />
                  <IntegrationLandingPage path='integrationlanding' />
                  <ContactCardPage path='contactcard/:orgId/:visitorId' />
                  <MinistryLoginPage path='ministrylogin' />
                  <MinistryCreatePage path='ministrycreate' />
                  <UserLoginPage path='userlogin' />
                  <UserCreatePasswordPage path='usercreatepassword' />
                  <KidsRegistrationPage path='childreg/:orgId/:visitorId' />
                  <UserInfoPage path='userinfo' />
                  <UserFirstVisitPage path='first' />
                  <MinistryInfoPage path='ministryinfo' />
                  <RegistrationInfo path='registrationinfo' />
                  <ForgotPassword path='forgotpassword' />
                  <ResetVisitorPassword path='pwdreset/user/:urlguid' />
              </Router>
            </CookiesProvider>
        {/*</LocationProvider>*/}
    </div>
  );

  function initializeReactGA() {
      ReactGA.initialize('UA-147379041-1');
      ReactGA.pageview('/');
      ReactGA.pageview('/userlogin');
      ReactGA.pageview('/userinfo');
      ReactGA.pageview('/ministrylogin');
      ReactGA.pageview('/ministryinfo');
  }
}

export default App;
