import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useCookies } from 'react-cookie';
import { navigate } from '@reach/router';
import TopNavBar from '../../components/navbar';
import SimpleReactValidator from 'simple-react-validator';
import Logo from '../../assets/images/logo_transparent.png';

import {
    Button,
    Container,
    Form,
    Grid,
    Image,
    Segment
} from 'semantic-ui-react';

const MinistryLoginPage = props => {
    let buttonRef = React.createRef();
    let validator = new SimpleReactValidator({autoForceUpdate: this});

    const {
        ministryUserInfo,
        updateMinistryUserEmail,
        updateMinistryUserPassword,
        updateMinistryUserKeepLoggedIn,
        updateMinistryUserLoginStatus,
        updateShowMinistryLoginError,
        performLogin,
        showMinistryLoginError
    } = props;

    const errorStyle = {
        color: 'red'
    };

    function getMessages() {
        return validator.getErrorMessages();
    }

    function getMessageForEmail(fieldName) {
        var message = validator.getErrorMessages().email;
        return message;
    }

    function getMessageForPassword(fieldName) {
        var message = validator.getErrorMessages().password;
        return message;
    }

    function RenderLoginError(message) {
        console.log('Login Message: ' + message);
        if (message === 'Unauthorized') {
            updateShowMinistryLoginError(true);
            console.log(showMinistryLoginError);
        } else {
            updateShowMinistryLoginError(false);
        }
    }

    const [cookies, setCookie] = useCookies(['loginToken', 'keepLoggedIn']);

    const handleSetMinistryUserEmail = (e) => {
        updateMinistryUserEmail(e.target.value);
    };

    const handleSetMinistryUserPassword = (e) => {
        updateMinistryUserPassword(e.target.value);
    };

    const handleSetMinistryUserKeepLoggedIn = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        updateMinistryUserKeepLoggedIn(value);
    };

    const handleLogin = () => {
        console.log(console.log(ministryUserInfo));
        if (validator.allValid()) {
            var userData = {
                email: ministryUserInfo.email,
                password: ministryUserInfo.password,
                keepLoggedIn: ministryUserInfo.keepLoggedIn
            };
            let result = performLogin(userData);
            result.then((serverResponse) => {
               if (serverResponse.data !== undefined) {
                   RenderLoginError(serverResponse);
                   setCookie('ministryloginToken', serverResponse.data.token);
                   setCookie('keepLoggedIn', ministryUserInfo.keepLoggedIn);
                   updateMinistryUserLoginStatus(true);
                   navigate('/ministryinfo');
               } else {
                   console.log(serverResponse);
                   RenderLoginError(serverResponse);
               }
            }).catch((err) => {

            });
        } else {
            // NOTE: may not be working
            validator.showMessages();
        }
    };

    const enterPressed = (e) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            console.log(buttonRef);
            //buttonRef.onclick();
            //buttonRef.click();
            //console.log('enter key pressed');
        }
    };

    useEffect(() => {

        validator.purgeFields();
        let keepLoggedInCookie = cookies.keepLoggedIn;
        let loginTokenCookie = cookies.ministryloginToken;

        /*        if (keepLoggedInCookie === 'true' && loginTokenCookie !== null && loginTokenCookie !== undefined) {
                    navigate('/userinfo');
                }*/


        // TODO: handle keepLoggedInCookie === false case
    }, []);


    return(
        <div>
            <TopNavBar />
            <Container>
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Image src={Logo} />
                        </Grid.Column>
                        <Grid.Column style={{ 'verticalAlign': 'middle'}}>
                            <Segment raised>
                                <h5>
                                    Please Login To View Ministry Information
                                </h5>
                            </Segment>
                            <Form>
                                <Form.Field>
                                    <input
                                        type="email"
                                        placeholder="Email address"
                                        defaultValue={ministryUserInfo.email}
                                        onChange={(e) => handleSetMinistryUserEmail(e)} />
                                    {validator.message('email', ministryUserInfo.email, 'required|email')}
                                </Form.Field>
                                <Form.Field>
                                    <input
                                        type="password"
                                        placeholder="Password"
                                        defaultValue={ministryUserInfo.password}
                                        onChange={(e) => handleSetMinistryUserPassword(e)}
                                        onKeyPress={enterPressed.bind(this)} />
                                    <p>{validator.message('password', ministryUserInfo.password, 'required')}</p>
                                </Form.Field>
                                <footer>
                                    {getMessages().email !== null ? (
                                        <div style={errorStyle}>
                                            <span>{getMessageForEmail('email')}</span>
                                        </div>
                                    ) : null}
                                    {getMessages().password !== null ? (
                                        <div style={errorStyle}>
                                            <span>{getMessageForPassword('password')}</span>
                                        </div>
                                    ) : null}
                                    {showMinistryLoginError ? (
                                        <div>
                                            Unauthorized
                                        </div>
                                    ) : null }
                                    <div className="clearfix note mb-30">
                                        <a className="float-right" href="#">Forgot Password?</a>
                                    </div>
                                    <Button type="button"
                                            className="ui primary button"
                                            variant="contained" disabled={!validator.allValid()}
                                            onClick={handleLogin} ref={buttonRef} >
                                        <i className="fa fa-check"> </i> OK, LOG IN
                                    </Button>
                                </footer>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>
    );
};

export default inject(({ store: { app }}) => ({
    performLogin: app.loginMinistryUser,
    updateMinistryUserEmail: app.setMinistryUserEmail,
    updateMinistryUserPassword: app.setMinistryUserPassword,
    updateMinistryUserKeepLoggedIn: app.setMinistryUserKeepLoggedIn,
    updateMinistryUserLoginStatus: app.setMinistryUserLoginStatus,
    ministryUserInfo: app.ministryUserInfo,
    updateShowMinistryLoginError: app.setShowMinistryLoginError,
    showMinistryLoginError: app.showMinistryLoginError
}))(observer(MinistryLoginPage));
