import React from 'react';
import {defer} from "q";
import {
    Container,
    Grid,
    Segment
} from 'semantic-ui-react';

const FeatureBox = () => {
    return(
        <React.Fragment>
            <Container>
                <Grid columns='3' divided>
                    <Grid.Row>
                        <Grid.Column style={{ 'textAlign': 'center'}}>
                            <h4><i className="fa fa-user-plus"></i>&nbsp;First Time Visitor Registration</h4>
                            <Segment raised>
                                Are you visiting a church for the first time?
                                Click here to find out how you can get registered.
                            </Segment>

                        </Grid.Column>
                        <Grid.Column style={{ 'textAlign': 'center'}}>
                            <h4><i className="fa fa-user"></i>&nbsp;Member/Visitor Info</h4>
                            <Segment raised>
                                Been here before?  Click here to find out more about what features are available to you.
                            </Segment>
                        </Grid.Column>
                        <Grid.Column style={{ 'textAlign': 'center'}}>
                            <h4><i className="fa fa-building"></i>&nbsp;Ministry Registration</h4>
                            <Segment raised>
                                At Church Contact, we believe your church or ministry could benefit greatly by what we have to offer.
                                If you would like to know more about how to sign up, click here.
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    );
};

export default FeatureBox;
