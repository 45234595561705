import React, { useEffect } from 'react';
import { navigate } from "@reach/router";
import TopNavBar from '../../components/navbar';
import TopSlider from '../../components/topslider';
//import FeatureBox from '../../components/featurebox';
import PricingCallout from '../../components/pricingcallout';
import Footer from "../../components/footer";
import FeatureBox from "../../components/featurebox";

const HomePage = props => {
    const {
        // eslint-disable-next-line no-unused-vars
        pageTitle
    } = props;

    useEffect(() => {
        //console.log(props);
        let orgId = props.orgId;
        let visitorId = props.visitorId;
        if (orgId !== undefined && visitorId !== undefined) {
            //console.log('there is an org id!');
            navigate('contactcard/' + orgId + '/' + visitorId);
        } else {
            //console.log('there is no org id.');
        }
    },[]);

    return(
        <div>
            <TopNavBar />
            <TopSlider />
            <FeatureBox />
            {/*<PricingCallout />*/}
            <Footer/>
            {/*<TopSlider />*/}
        </div>
    );
};

export default HomePage;
