import React from "react";
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../components/navbar';
//import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import SmsImage from '../../assets/images/SMSLink2.jpg';
import {navigate} from "@reach/router";
import Media from 'react-media';

const RegistrationInfo = props => {

    const navigateToUserLogin = () => {
        navigate('userlogin');
    };

    return (
        <div>
            <TopNavBar />
            <CssBaseline />
            <Container maxWidth="lg">
                <h3>Registration</h3>
                <div className="heading-title heading-border heading-color mt-0">
                    <h2 className="fs-18 fw-400">How To Get Registered with ChurchContact</h2>
                </div>
                <hr />
                <Typography>
                    Registration is easy, but you'll need to use the registration phone number
                    provided by the church or ministry you've visited or that you are member of.
                </Typography>
                <br />
                <Typography>
                    Once you have the number just text <b>FIRST</b> to the number.  This will link you
                    with the ministry and allow you to register.
                </Typography>
                <br />
                <Typography>
                    If you are confident you've registered, try the 'Forgot Password' link on the login screen.
                    <br />
                    <br />
                    <Link onClick={navigateToUserLogin} style={{ 'cursor': 'pointer'}}>
                        Back to Login Page
                    </Link>
                </Typography>

                <br />

                <Media query="(max-width:599px)">
                    {matches => matches ? (
                        <img src={SmsImage} width="320" />
                    ): (
                        <img src={SmsImage} width="480" />
                    )}
                </Media>

            </Container>
        </div>
    )
};

export default inject(({ store: {app}}) => ({

}))(observer(RegistrationInfo));
