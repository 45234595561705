import { observable, action, toJS } from 'mobx';
//import { navigate } from "@reach/router";
import axios from 'axios';
import Api from '../common/apiHelper';
import Validator from 'validatorjs';

import VisitorInfo from '../routes/contactcard/visitor';
import MinistryUserInfo from './ministryuser';
import MinistryInfo from "./ministry";
import KidInfo from '../routes/kids/kidsregistration/kidinfo';
//import {initialiseAgGridWithAngular1} from "ag-grid-community";

export default class AppStore {
    rootStore;
    //baseUrl = "https://localhost:5001/";
    //baseUrl = "http://api.churchcontact.org/";
    baseUrl = "http://api.cc.giwutechapps.com/";

    @observable pageTitle;
    @observable ministryName;
    @observable visitorId;
    @observable visitorMobilePhone;
    @observable visitorFirstName;
    @observable visitorLastName;
    @observable visitorZipCode;
    @observable visitorEmailAddress;
    @observable visitorPassword;
    @observable showVisitorLoginError;
    @observable showMinistryLoginError;

    @observable visitorInfo = VisitorInfo;
    @observable ministryUserInfo = MinistryUserInfo;
    @observable ministryInfo = MinistryInfo;

    @observable kidsToRegister = [];

    constructor(rootStore) {
        //let mySecret = process.env;
        //console.log(mySecret);
        this.rootStore = rootStore;
        this.pageTitle = 'Church Contact - Connecting with People Made Simple';

        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        // axios.defaults.headers.get['Accepts'] = 'application/json';
        // axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8';
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response === undefined) {
                return 'Bad Request';
            }
            if (error.response.status === 401) {
                return 'Unauthorized';
            }
            else return error;
        });
    }

    getKidInfo(kid) {
      let kidInfo = {kidNumber: kid.kidNumber, firstName: kid.firstName, lastName: kid.lastName, age: kid.age};
      return kidInfo;
    }

    async addKidVisit(kid, parent)  {
        let res = await axios.post(this.baseUrl + 'api/child', {
            FirstName: kid.firstName,
            LastName: kid.lastName,
            Age: kid.age,
            VisitNumber: kid.kidNumber,
            ParentsPhone: parent.mobilePhone,
            ParentId: parent.id
        });
        return res;
    }

    @action
    addKid = kid => {
      this.kidsToRegister.push(new KidInfo(kid.kidNumber, kid.firstName, kid.lastName, kid.age));
      //console.log("Kids: ", this.kidsToRegister.map(x => this.getKidInfo(x)));
    };

    @action
    addKids = (parent) => {
        //console.log(parent.id);
        this.kidsToRegister.map(x => this.addKidVisit(x, parent).then((response)=> {
            console.log('Child added:' + response);
        }));
    };

    @action
    setPageTitle = title => {
        this.pageTitle = title;
    };

    @action
    setMinistryName = name => {
        this.ministryName = name;
    };

    @action
    setVisitorId = id => {
        this.visitorInfo.id = id;
    };

    @action
    setVisitorMobilePhone = phone => {
        this.visitorInfo.mobilePhone = phone;
    };

    @action
    setVisitorFirstName = name => {
        this.visitorInfo.firstName = name;
    };

    @action
    setVisitorLastName = name => {
        this.visitorInfo.lastName = name;
    };

    @action
    setVisitorEmailAddress = email => {
        this.visitorInfo.email = email;
    };

    @action
    setVisitorAddress = address => {
      this.visitorInfo.address = address;
    };

    @action
    setVisitorAddress2 = address => {
      this.visitorInfo.address2 = address;
    };

    @action
    setVisitorCity = city => {
        this.visitorInfo.city = city;
    };

    @action
    setVisitorState = state => {
      this.visitorInfo.state = state;
    };

    @action
    setVisitorZipCode = zip => {
        this.visitorInfo.zipCode = zip;
    };

    @action
    setVisitorPassword = password => {
        this.visitorInfo.password = password;
    };

    @action
    setVisitorKeepLoggedIn = value => {
        this.visitorInfo.keepLoggedIn = value;
    };

    @action
    setVisitorLoginStatus = value => {
      this.visitorInfo.visitorIsLoggedIn = value;
      console.log(this.visitorInfo.visitorIsLoggedIn);
    };

    @action
    setShowVisitorLoginError = value => {
        this.showVisitorLoginError = value;
    };

    @action
    getVisitor = (id) => {
        let res = axios.get(this.baseUrl + 'api/visitor/' + id)
            .then((data) => {
                return data;
            });
        return res;
    };

    @action
    getVisitorChildren = (id) => {
      let res = axios.get(this.baseUrl + 'api/child/parent/' + id)
          .then((data) => {
              return data;
          });
      return res;
    };

    @action
    sendChildRegEmailToMinistry = (visitorId, orgId) => {
        let res = axios.post(this.baseUrl + 'api/child/regemail', {
            visitorId: visitorId,
            orgId: orgId
        });
        return res;
    };

    // **** Ministry User ****

    @action
    setMinistryUserKeepLoggedIn = value =>  {
        this.ministryUserInfo.keepLoggedIn = value;
    };

    @action
    setMinistryUserLoginStatus = value => {
        this.ministryUserInfo.ministryUserIsLoggedIn = value;
    };

    @action
    setShowMinistryLoginError = value => {
      this.showMinistryLoginError = value;
    };

    @action
    setMinistryUserEmail = email => {
        this.ministryUserInfo.email = email;
    };

    @action
    setMinistryUserPassword = password => {
        this.ministryUserInfo.password = password;
    };

    @action
    setMinistryInfoName = name => {
        this.ministryInfo.ministryName = name;
    };

    @action
    setMinistryCode = code => {
        this.ministryInfo.ministryCode = code;
    };

    @action
    setMinistrySmsPhoneNumber = phone => {
      this.ministryInfo.smsPhoneNumber = phone;
    };

    @action
    setMinistryTaxId = taxid => {
        this.ministryInfo.taxIdNumber = taxid;
    };

    @action
    setMinistryWebsiteLink = link => {
        this.ministryInfo.websiteLink = link;
    };

    @action
    setMinistryRedirectLink = link => {
        this.ministryInfo.redirectLink = link;
    };

    @action
    updateVisitorReg = async (userData) => {
        let res = await axios.patch( this.baseUrl + 'api/auth/register', {
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            mobilePhone: userData.mobilePhone,
            password: userData.password,
            orgName: userData.orgName,
        }).then((serverResponse) => {
            // if (serverResponse.data.success === true) {
            //     navigate('/');
            // }
            return serverResponse.data;
        });
        return res;
    };

    @action
    updateVisitorData = async (userData) => {
      let res = await axios.patch(this.baseUrl + 'api/visitor', {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          address: userData.address,
          address2: userData.address2,
          city: userData.city,
          state: userData.state,
          zipCode: userData.zipCode,
          email: userData.email,
          mobilePhone: userData.mobilePhone
        });
      return res;
    };

    @action
    loginVisitor = async (userData) => {
      let res = await axios.post(this.baseUrl + 'api/auth/visitor/login', {
         email: userData.email,
         password: userData.password,
          keepLoggedIn: userData.keepLoggedIn
      });
      return res;
    };

    @action
    loginMinistryUser = async (userData) => {
      let res = await axios.post(this.baseUrl + 'api/auth/login', {
          email: userData.email,
          password: userData.password,
          keepLoggedIn: userData.keepLoggedIn
      });
      return res;
    };

    @action
    getUserInfo = async (token) => {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        let bodyParams = {
            key:"value"
        };

        let res = await axios.post(this.baseUrl + 'api/visitor/info', bodyParams, config);
        return res;
    };

    @action
    getMinistry = async(id) => {
        let res = await axios.get(this.baseUrl + 'api/ministry/' + id)
            .then((data) => {
                return data;
            });
        return res;
    };

    @action
    getMinistryInfo = async (token) => {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        let bodyParams = {
            key:"value"
        };
      let res = await axios.post(this.baseUrl + 'api/ministry/info', bodyParams, config);
      return res;
    };

    @action
    getMinistryVisitors = async (token) => {
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };

        let bodyParams = {
            key:"value"
        };

        let res = await axios.post(this.baseUrl + 'api/ministry/filter?visitors=', bodyParams, config);
        return res;
    };

    @action
    resetUserPassword = async (email) => {
      let res = await axios.post(this.baseUrl + 'api/auth/visitor/getresetpwdlink', {
          email: email
      });
      return res;
    };

    @action
    updateUserPassword = async (email, password) => {
      let res = await axios.post(this.baseUrl + '', {

      });
      return res;
    };

    getFlattenedValues = (valueKey = 'value') => {
        let data = {};
        let form = toJS(this.form).fields;
        Object.keys(form).map(key => {
            data[key] = form[key][valueKey];
            return data;
        });
        return data
    };

    @action
    onFieldChange = (field, value) => {
        this.form.fields[field].value = value;
        var validation = new Validator(
            this.getFlattenedValues('value'),
            this.getFlattenedValues('rule'));
        this.form.meta.isValid = validation.passes();
        this.form.fields[field].error = validation.errors.first(field)
    };

    @action
    setError = (errMsg) => {
        this.form.meta.error = errMsg
    }
}
