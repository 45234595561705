import { observable } from "mobx";

const MinistryUserInfo = observable({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    password: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    keepLoggedIn: false,
    ministryUserIsLoggedIn: false
});

export default MinistryUserInfo;
