import React from "react";
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../../components/navbar';
//import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const ChildrensMinistryLandingPage = props => {
    return (
        <div>
            <TopNavBar />
            <CssBaseline />
            <Container maxWidth="lg">
                <h3>Children's Ministry</h3>
                <div className="heading-title heading-border heading-color mt-0">
                    <h2 className="fs-18 fw-400">COMING SOON</h2>
                    <p className="fs-13">Taking Children's Ministry Registration to the Next Level</p>
                </div>

                <p>
                    Our Platform will speed up the process of getting children registered.  Updates are coming soon!
                </p>
            </Container>
        </div>
    )
};

export default inject(({ store: {app}}) => ({

}))(observer(ChildrensMinistryLandingPage));
