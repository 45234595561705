import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'video-react/dist/video-react.css';
import { Provider } from "mobx-react";
import App from './App';
import * as serviceWorker from './serviceWorker';

import MainStore from './stores';
const mainStore = new MainStore();

// window.$ = window.jQuery = require('jquery');


ReactDOM.render(
    <Provider store={mainStore}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
