import React from "react";
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../../components/navbar';
//import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const IntegrationLandingPage = props => {
    return (
        <div>
            <TopNavBar />
            <CssBaseline />
            <Container maxWidth="lg">
                <h3>Integration</h3>
                <div className="heading-title heading-border heading-color mt-0">
                    <h2 className="fs-18 fw-400">Updates COMING SOON</h2>
                    <p className="fs-13">Making it easy to connect.</p>
                </div>

                <p>
                    It is one thing to get visitors into our system, but quite another connecting their information to
                    your existing system.  We are working hard on making it simple to sync data between systems, like
                    FellowshipOne!
                </p>
            </Container>
        </div>
    )
};

export default inject(({ store: {app}}) => ({

}))(observer(IntegrationLandingPage));
