import React from 'react';
//import { navigate } from '@reach/router';
import SimpleReactValidator from 'simple-react-validator';
import TopNavBar from '../../../components/navbar';
import Logo from '../../../assets/images/logo_transparent.png';
import {
    Button,
    Container,
    Form,
    Grid,
    Image,
    //Modal,
    Segment
} from 'semantic-ui-react';
import {inject, observer} from "mobx-react";

const ResetUserPassword = (props) => {
    const {
        visitorInfo,
        setUserPassword,
        updateUserPassword,
        updateVisitorEmail
    } = props;

    const errorStyle = {
        color: 'red'
    };

    const handleSetVisitorEmail = (e) => {
        updateVisitorEmail(e.target.value);
    };

    const handleSetVisitorPassword = (e) => {
        setUserPassword(e.target.value);
    };

    const handleUpdatePassword = (e) => {
        let result = updateUserPassword(visitorInfo.email);
        result.then((serverResponse) => {
            if (serverResponse.data !== undefined && serverResponse.data.success === true)
            {
                //setModal(true);
            } else {
                //setErrorModal(true);
            }
        }).catch((err) => {
            console.log(err);
        });
    };

    function getMessages() {
        return validator.getErrorMessages();
    }

    function getMessageForEmail(fieldName) {
        var message = validator.getErrorMessages().email;
        return message;
    }

    let buttonRef = React.createRef();
    let validator = new SimpleReactValidator({autoForceUpdate: this, locale: 'en'});

    return(
        <React.Fragment>
            <TopNavBar />
            <Container>
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Image src={Logo} />
                        </Grid.Column>
                        <Grid.Column style={{ 'verticalAlign': 'middle'}}>
                            <Segment raised>
                                <h5>
                                    Please Update Your Password.
                                </h5>
                            </Segment>
                            <Form>
                                <Form.Field>
                                    <input
                                        type="email"
                                        placeholder="Email address"
                                        defaultValue={visitorInfo.email}
                                        onChange={(e) => handleSetVisitorEmail(e)} />
                                    {validator.message('email', visitorInfo.email, 'required|email')}
                                </Form.Field>
                                <footer>
                                    {getMessages().email !== null ? (
                                        <div style={errorStyle}>
                                            <span>{getMessageForEmail('email')}</span>
                                        </div>
                                    ) : null}
                                    <br />
                                    <Button type="button"
                                            className="ui primary button"
                                            disabled={!validator.allValid()}
                                            onClick={handleUpdatePassword} ref={buttonRef}>
                                        <i className="fa fa-check"> </i>&nbsp; Update Password
                                    </Button>
                                </footer>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </React.Fragment>
    );

};

export default inject(({ store: { app }}) => ({
    visitorInfo: app.visitorInfo,
    updateUserPassword: app.updateUserPassword,
    setUserPassword: app.setVisitorPassword,
    updateVisitorEmail: app.setVisitorEmailAddress
}))(observer(ResetUserPassword));
