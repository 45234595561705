import { observable } from "mobx";

class KidInfo {
    @observable kidNumber = '';
    @observable firstName = '';
    @observable lastName = '';
    @observable age = 0;

    constructor(number, firstName, lastName, age) {
        this.kidNumber = number;
        this.firstName = firstName;
        this.lastName = lastName;
        this.age = age;
    }
}

export default KidInfo;
