import React from 'react';
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../components/navbar';

const MinistryCreatePage = props => {
    return(
        <div>
            <TopNavBar />
            <hr />
            <div className="container h-100">
                Ministry Create Page
            </div>
        </div>
    );
};

export default inject(({ store: { app }}) => ({

}))(observer(MinistryCreatePage));
