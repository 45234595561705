import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import TopNavBar from '../../../components/navbar';
//import VisitorInfo from "../../contactcard/visitor";
//import NumericInput from 'react-numeric-input';
import KidSignUp from "./kidsignup";
import _ from 'lodash';


import {
    Button,
    Container,
    Divider,
    Input
} from 'semantic-ui-react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {navigate} from "@reach/router";

const KidsRegistrationPage = props => {

    const {
        addKids,
        visitorInfo,
        getVisitor,
        getVisitorChildren,
        getMinistry,
        sendChildRegEmailToMinistry,
        updateVisitorMobilePhone,
        updateVisitorFirstName,
        updateVisitorLastName,
        updateVisitorEmail,
        updateVisitorId
    } = props;

    const [open, setOpen] = useState(false);
    const [numberOfKids, setNumberOfKids] = useState(1);
    const [ministryDetails, setMinistryDetails] = useState({ministryName: '', websiteLink: '', ministryId: ''});


    const handleClickClose = () => {
        setOpen(false);
        let link = ministryDetails.websiteLink;
        navigate(link);
    };

    const handleSetNumberOfKids = (e) => {
        setNumberOfKids(e.target.value);
    };

    const handleSetMinistryDetails = (name, link, id) => {
        setMinistryDetails({ministryName: name, websiteLink: link, ministryId: id});
    };

    const setVisitorId = (id) => {
        updateVisitorId(id);
    };

    const setVisitorMobilePhone = (phone) => {
        updateVisitorMobilePhone(phone);
    };

    const setVisitorFirstName = (name) => {
        updateVisitorFirstName(name);
    };

    const setVisitorLastName = (name) => {
        updateVisitorLastName(name);
    };

    const setVisitorEmail = (email) => {
        updateVisitorEmail(email);
    };

    const kidsSignUps = _.times(numberOfKids, (i) => (
        <div key={i}>
            <KidSignUp parentId={props.visitorId} />
            <Divider />
        </div>
    ));

    const createKidSignUps = (num) => {
      return; // kids.map(createKidSignUp);
    };

    const handleAddKids = () => {
      addKids(visitorInfo);
      sendChildRegEmailToMinistry(props.visitorId, ministryDetails.ministryId);
      setOpen(true);
    };

    useEffect(() => {
        //console.log(props.orgId);
        let visitorPromise = getVisitor(props.visitorId);
        visitorPromise.then((data) => {
            if (data !== undefined) {
                //console.log(data.data);
                setVisitorId(data.data.id);
                setVisitorMobilePhone(data.data.mobilePhone);
                setVisitorEmail(data.data.email);
                setVisitorFirstName(data.data.firstName);
                setVisitorLastName(data.data.lastName);
            }
        });
        let visitorChildrenPromise = getVisitorChildren(props.visitorId);
        visitorChildrenPromise.then((data) => {
           if (data !== undefined) {

           }
        });
        let ministryPromise = getMinistry(props.orgId);
        ministryPromise.then((data) => {
            if (data !== undefined) {
                console.log(data.data);
                handleSetMinistryDetails(data.data.ministryName, data.data.websiteLink, data.data.id);
            }
        });
    }, []);

    return(
        <React.Fragment>
            <TopNavBar />
            <hr />
            <Container >
                Kids Registration Page
                <hr />
                <div>
                    <h4>{visitorInfo.firstName}, How many children would you like to register?</h4>
                    <Input type='number' className='ui input' value={numberOfKids}
                           onChange={(e) => handleSetNumberOfKids(e)} />
                    <br /><br />
                    <div className='col-md-3'>
                        {/*<KidSignUp />*/}
                        {kidsSignUps}
                    </div>

                    <div id='kidRegistrationElements'>
                        {createKidSignUps(2)}
                    </div>
                    <hr />
                    <Button className='ui button primary' onClick={handleAddKids}>Done</Button>
                    <Dialog open={open} onClose={handleClickClose}>
                        <DialogTitle id="responsive-dialog-title">{"Success"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                You've successfully registered your child(ren).  Please follow the instructions the church/ministry provides.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button  className="ui primary button"  onClick={handleClickClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Container>
        </React.Fragment>
    );
};

export default inject(({ store: { app }}) => ({
    addKids: app.addKids,
    visitorInfo: app.visitorInfo,
    getVisitor: app.getVisitor,
    getVisitorChildren: app.getVisitorChildren,
    getMinistry: app.getMinistry,
    sendChildRegEmailToMinistry: app.sendChildRegEmailToMinistry,
    updateVisitorMobilePhone: app.setVisitorMobilePhone,
    updateVisitorFirstName: app.setVisitorFirstName,
    updateVisitorLastName: app.setVisitorLastName,
    updateVisitorEmail: app.setVisitorEmailAddress,
    updateVisitorId: app.setVisitorId
}))(observer(KidsRegistrationPage));
