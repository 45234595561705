import axios from 'axios';
import { url } from './constants';

const config = token => ({
    headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': ''
    }
});

const init = (apiRoute, payload, token) => ({
    method: 'post',
    url: apiRoute,
    mode: 'cors',
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'https://localhost:5001'
    },
    data: typeof payload === 'string' ? payload : JSON.stringify(payload)
});

const post = (api, token, payload) =>
    axios(init(url(api), payload, token)).then(
        response =>
            new Promise((resolve, reject) => {
                const { status, data } = response;
                if (status >= 200) {
                    resolve(data);
                }
            })
    );

const get = (api, token) =>
    axios(url(api), config(token)).then(
        response =>
            new Promise((resolve, reject) => {
                const { status, data } = response;
                if (status >= 200) {
                    resolve(data)
                }
            })
    );



export default { get, post };
